export const defaultStep = 1;
export const totalSteps = 2;

export const getStepInfo = (step: number, isFirstProjectCreation: boolean) => {
  const infoByStep: { [key: number]: { title: string; description: string } } = {
    1: {
      title: isFirstProjectCreation
        ? 'Welcome! Let’s set up your first event!'
        : 'Welcome! Let’s set up your new event!',
      description:
        'Start by giving your event a name so you can easily organize all your research and planning in one place.\nPro tip: use the name of an upcoming event you’re currently working on!',
    },
    2: {
      title: 'Begin your content research!',
      description:
        'Enter an industry or theme of interest you plan to cover at your event and we’ll instantly start searching the most relevant topics, speakers, and sponsors around it.\nPro tip: Keep it clear and focused — adding a brief context like location can refine the results, but stick to one theme per search for the best insights.',
    },
  };

  return infoByStep[step];
};
