import React, { useCallback, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import styles from './MoveToModal.module.css';

import { CombinedExtract } from '../../../services/api/workspace/types';
import { useUserSettingsStore } from '../../../stores';
import { useFocusOnCondition } from '../../../hooks';
import { useFetchProjectDetails } from '../../../services/api/workspace/projects/hooks';
import { useCreateFolder } from '../../../services/api/workspace/folders/hooks';
import { useMovePersonToAnotherFolder } from '../../../services/api/workspace/people/hooks';
import { useMoveTopicToAnotherFolder } from '../../../services/api/workspace/topics/hooks';
import { useMoveSponsorToAnotherFolder } from '../../../services/api/workspace/sponsors/hooks';

export interface MoveToModalProps {
  open: boolean;
  onClose: () => void;
  selectedItem?: CombinedExtract | null;
}

const MoveToModal: React.FC<MoveToModalProps> = ({ open, onClose, selectedItem }) => {
  const { selectedProjectId, selectedFolderId } = useUserSettingsStore();

  const [addingFolder, setAddingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [selectedDestinationFolderId, setSelectedDestinationFolderId] = useState<string>('');

  const { data: projectDetails, refetch: refetchProjectDetails } =
    useFetchProjectDetails(selectedProjectId);
  const { mutate: createFolder } = useCreateFolder();
  const { mutate: movePersonToAnotherFolder } = useMovePersonToAnotherFolder();
  const { mutate: moveTopicToAnotherFolder } = useMoveTopicToAnotherFolder();
  const { mutate: moveSponsorToAnotherFolder } = useMoveSponsorToAnotherFolder();

  const inputRef = useFocusOnCondition(addingFolder);

  const resetModalState = useCallback(() => {
    setNewFolderName('');
    setAddingFolder(false);
  }, [setAddingFolder]);

  const handleClose = () => {
    onClose();
    resetModalState();
  };

  const handleListItemClick = () => {
    const entities = [
      {
        id: selectedItem?.topic_extract_id,
        moveToFolder: moveTopicToAnotherFolder,
      },
      {
        id: selectedItem?.people_extract_id,
        moveToFolder: movePersonToAnotherFolder,
      },
      {
        id: selectedItem?.sponsor_extract_id,
        moveToFolder: moveSponsorToAnotherFolder,
      },
    ];

    const { moveToFolder, id: extractId } = entities.find((entity) => entity.id) || {};

    const folderId = selectedFolderId;

    moveToFolder?.(
      {
        extractId: extractId || '',
        folderId,
        folderIdDestination: selectedDestinationFolderId,
      },
      {
        onSuccess: () => {
          onClose();
          resetModalState();
        },
      },
    );
  };

  const handleCreateFolderSave = () => {
    if (newFolderName.trim()) {
      createFolder(newFolderName, { onSuccess: () => refetchProjectDetails() });
      resetModalState();
    }
  };

  return (
    <Dialog
      className={styles.dialogContainer}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogContent className={styles.content}>
        <DialogTitle className={styles.title}>Move {selectedItem?.name}</DialogTitle>

        <List className={styles.list}>
          {projectDetails?.user.folders.map((folder) => (
            <React.Fragment key={folder.id}>
              <ListItem disableGutters>
                {folder.id === selectedFolderId ? null : (
                  <ListItemButton
                    className={`${styles.folder} ${selectedDestinationFolderId === folder.id ? styles.selectedItem : ''}`}
                    onClick={() => setSelectedDestinationFolderId(folder.id)}
                  >
                    <FolderOpenIcon className={styles.folderIcon} />
                    <ListItemText primary={folder.name} />
                  </ListItemButton>
                )}
              </ListItem>
            </React.Fragment>
          ))}

          <ListItem className={styles.addFolderBox}>
            {addingFolder ? (
              <>
                <TextField
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="Enter a folder title..."
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCreateFolderSave();
                    }
                  }}
                  className={styles.textField}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FolderOpenIcon />
                      </InputAdornment>
                    ),
                    inputProps: { style: { padding: '16px 16px 16px 0' } },
                  }}
                />
                <Box className={styles.buttonBox}>
                  <Button
                    size="large"
                    onClick={handleCreateFolderSave}
                    variant="contained"
                    color="primary"
                    className={styles.saveButton}
                  >
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={resetModalState}
                    variant="outlined"
                    className={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => setAddingFolder(true)}
                className={styles.addFolderButton}
              >
                <Typography variant="body2">Add folder</Typography>
              </Button>
            )}
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <Button onClick={handleClose}>Close</Button>
        <Button
          disabled={!selectedDestinationFolderId}
          variant="contained"
          onClick={handleListItemClick}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveToModal;
