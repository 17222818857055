import axios from 'axios';
import axiosRetry from 'axios-retry';
import { BASE_API_URL, headers } from './config';

// Configure axios-retry
axiosRetry(axios, {
  retries: 5, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Use exponential backoff
  // attach callback to each retry to handle logging or tracking
  onRetry: (retryCount, error) => console.log(`Retrying request (${retryCount}): ${error}`),
  // Specify conditions to retry on, this is the default
  // which will retry on network errors or idempotent requests (5xx)
  retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error),
});

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers,
});

export const setAuthToken = (token: string) => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setProjectId = (projectId: string) => {
  apiClient.defaults.headers.common['X-Project-ID'] = projectId;
};

export default apiClient;
