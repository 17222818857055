import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { setProjectId } from '../services/api/apiClient';

interface UserSettingsState {
  isSearchActive: boolean;
  toggleSearch: () => void;
  selectedSearchId: string;
  setSelectedSearchId: (searchTerm: string) => void;
  selectedProjectId: string;
  setSelectedProjectId: (projectId: string) => void;
  selectedFolderId: string;
  setSelectedFolderId: (folderId: string) => void;
  activeTabIndex: number;
  setActiveTabIndex: (tab: number) => void;
  passedOnboarding: boolean;
  setPassedOnboarding: (passedOnboarding: boolean) => void;
}

const useUserSettingsStore = create<UserSettingsState>()(
  persist(
    (set) => ({
      selectedSearchId: '',
      selectedProjectId: '',
      selectedFolderId: '',
      activeTabIndex: 0,
      isSearchActive: false,
      passedOnboarding: false,
      toggleSearch: () => set((state) => ({ isSearchActive: !state.isSearchActive })),
      setSelectedSearchId: (searchId: string) =>
        set(() => ({
          selectedSearchId: searchId,
        })),
      setSelectedProjectId: (projectId: string) => {
        setProjectId(projectId);

        return set(() => ({
          selectedProjectId: projectId,
        }));
      },
      setSelectedFolderId: (folderId: string) =>
        set(() => ({
          selectedFolderId: folderId,
        })),
      setActiveTabIndex: (tab: number) =>
        set(() => ({
          activeTabIndex: tab,
        })),
      setPassedOnboarding: (passedOnboarding: boolean) =>
        set(() => ({
          passedOnboarding,
        })),
    }),
    {
      name: 'userSettings',
    },
  ),
);

export default useUserSettingsStore;
