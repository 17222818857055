import { Grade } from '../enums';

export const criteriaLabels = {
  [Grade.DEFAULT]: 'Default',
  [Grade.AUTHORITY]: 'Most Influential',
  [Grade.RECENCY]: 'Most Trending',
  [Grade.RELEVANCY]: 'Most Relevant',
  [Grade.SPECIFICITY]: 'Most Specific',
  [Grade.TRUST]: 'Most Trusted',
  [Grade.INDUSTRY_MATCH]: 'Audience Match',
  [Grade.SPONSORSHIP_TRACK_RECORD]: 'Sponsorship History',
};
