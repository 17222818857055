import React, { useEffect, useState } from 'react';
import { Tooltip, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface AddToWorkspaceButtonProps {
  id?: string;
  buttonClassName?: string;
  defaultValue?: boolean;
  onClick: () => void;
}

const AddToWorkspaceButton: React.FC<AddToWorkspaceButtonProps> = ({
  onClick,
  buttonClassName,
  defaultValue = false,
  id,
}) => {
  const [added, setAdded] = useState<boolean>(defaultValue);

  useEffect(() => setAdded(defaultValue), [defaultValue]);

  return (
    <Tooltip placement="top" title={added ? 'Remove from workspace' : 'Add to workspace'}>
      <Button
        id={id}
        className={buttonClassName}
        size="small"
        onClick={onClick}
        variant={added ? 'contained' : 'outlined'}
        color="primary"
      >
        {added ? <RemoveIcon /> : <AddIcon />}
      </Button>
    </Tooltip>
  );
};

export default AddToWorkspaceButton;
