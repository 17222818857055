import React, { useEffect, useState } from 'react';
import { Box, Drawer, Typography, Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SearchDetails from '../../components/dashboard/SearchDetails/SearchDetails';
import { SearchCreateResponse } from '../../services/api/search/types';
import {
  useFetchFullSearch,
  useFetchSearches,
  useRunSearch,
} from '../../services/api/search/hooks';
import styles from './Dashboard.module.css';
import SearchHistoryList from '../../components/dashboard/SearchHistoryList/SearchHistoryList';
import CreateProjectModal from '../../components/shared/CreateProjectModal/CreateProjectModal';
import { useFetchProjects } from '../../services/api/workspace/projects/hooks';
import SearchPanel from '../../components/dashboard/SearchPanel/SearchPanel';
import { useUserSettingsStore } from '../../stores';
import Joyride, { ACTIONS, CallBackProps, Events, EVENTS, STATUS } from 'react-joyride';
import { ActiveTabsIndexes } from '../../constants';
import { OnboardingIds } from '../../enums';

import { steps, stepIndexById } from './Dashboard.utils';

const Dashboard = () => {
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const {
    selectedSearchId,
    setSelectedSearchId,
    activeTabIndex,
    passedOnboarding,
    setPassedOnboarding,
  } = useUserSettingsStore();
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const { data: projects, isFetched: projectsFetched } = useFetchProjects({});

  const { data: searchHistory, isLoading } = useFetchSearches();

  const { mutate: runSearch, isPending: searchLoading } = useRunSearch();

  const {
    data: searchDetails,
    isLoading: loadingSearchDetails,
    isFetched: searchDetailsFetched,
  } = useFetchFullSearch(selectedSearchId);

  useEffect(() => {
    if (!isLoading && searchDetails) {
      setSelectedSearchId(searchDetails.search_id);
    }
  }, [searchDetails, isLoading, setSelectedSearchId]);

  useEffect(() => {
    if (
      searchDetailsFetched &&
      activeTabIndex === ActiveTabsIndexes.TOPICS &&
      selectedSearchId &&
      !passedOnboarding
    ) {
      setIsTourRunning(true);
    }
  }, [searchDetailsFetched, activeTabIndex, selectedSearchId, passedOnboarding]);

  useEffect(() => {
    if (projectsFetched && !projects.length) {
      setCreateProjectModalOpen(true);
    }
  }, [projects.length, projectsFetched]);

  const handleSearch = (searchTerm: string) => {
    runSearch(searchTerm, {
      onSuccess: (data: SearchCreateResponse) => setSelectedSearchId(data.search_id),
    });
  };

  const handleSearchButtonClick = () => setSelectedSearchId('');

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setIsTourRunning(false);
      setStepIndex(0);
      setPassedOnboarding(true);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (
        (index === stepIndexById[OnboardingIds.TOPIC_ROW] &&
          !searchDetails?.topic_results.length) ||
        (index === stepIndexById[OnboardingIds.TOPIC_DRILL_DOWN] &&
          !searchDetails?.people_results.length) ||
        (index === stepIndexById[OnboardingIds.PERSON_GOOGLE] &&
          !searchDetails?.sponsors_results.length) ||
        index === stepIndexById[OnboardingIds.PEOPLE_TAB] ||
        index === stepIndexById[OnboardingIds.SPONSORS_TAB]
      ) {
        setIsTourRunning(false);
      } else {
        setStepIndex(nextStepIndex);
      }
    }
  };

  useEffect(() => {
    if (isTourRunning) return;

    if (
      searchDetails?.topic_results.length &&
      stepIndex === stepIndexById[OnboardingIds.TOPIC_ROW]
    ) {
      setIsTourRunning(true);
    }

    if (
      (stepIndex === stepIndexById[OnboardingIds.TOPIC_DRILL_DOWN] &&
        searchDetails?.people_results.length) ||
      (stepIndex === stepIndexById[OnboardingIds.PERSON_GOOGLE] &&
        searchDetails?.sponsors_results.length)
    ) {
      setIsTourRunning(true);
      setStepIndex(stepIndex + 1);
    }

    if (
      activeTabIndex === ActiveTabsIndexes.PEOPLE &&
      stepIndex === stepIndexById[OnboardingIds.PEOPLE_TAB]
    ) {
      setIsTourRunning(true);
      setStepIndex(stepIndexById[OnboardingIds.PERSON_ROW]);
    }
    if (
      activeTabIndex === ActiveTabsIndexes.SPONSORS &&
      stepIndex === stepIndexById[OnboardingIds.SPONSORS_TAB]
    ) {
      setIsTourRunning(true);
      setStepIndex(stepIndexById[OnboardingIds.SPONSOR_ROW]);
    }
  }, [searchDetails, isTourRunning, stepIndex, activeTabIndex]);

  return (
    <Box className={styles.container}>
      <Drawer variant="permanent" className={styles.drawer} classes={{ paper: styles.drawerPaper }}>
        <Button
          id={OnboardingIds.NEW_SEARCH_BUTTON}
          onClick={handleSearchButtonClick}
          startIcon={<SearchIcon />}
          variant="outlined"
          className={styles.startButton}
        >
          NEW SEARCH
        </Button>
        <Box id={OnboardingIds.RECENT_SEARCHES}>
          {searchHistory.length > 0 && (
            <Typography variant="h6" className={styles.title}>
              Recent searches
            </Typography>
          )}

          <Box className={styles.searchHistory}>
            <SearchHistoryList
              isLoading={isLoading}
              searchHistory={searchHistory}
              selectedSearchId={selectedSearchId}
              setSelectedSearchId={setSelectedSearchId}
            />
          </Box>
        </Box>
      </Drawer>
      <Box className={styles.mainContent}>
        {selectedSearchId && (
          <SearchDetails loading={loadingSearchDetails} searchDetails={searchDetails} />
        )}
        {!selectedSearchId && searchLoading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress />
          </Box>
        )}
        {!selectedSearchId && !searchLoading && <SearchPanel handleSearch={handleSearch} />}
      </Box>
      <CreateProjectModal
        isFirstProjectCreation
        isOpen={createProjectModalOpen}
        onClose={() => setCreateProjectModalOpen(false)}
        projectsLength={projects.length}
      />
      <Joyride
        callback={handleJoyrideCallback}
        run={isTourRunning}
        stepIndex={stepIndex}
        continuous
        disableScrolling
        hideCloseButton
        locale={{
          next: 'Continue',
          last: 'All set!',
        }}
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#1976d2',
          },
          tooltipContainer: {
            zIndex: 10000,
          },
          overlay: {
            zIndex: 9999,
          },
        }}
        steps={steps}
      />
    </Box>
  );
};

export default Dashboard;
