import React, { memo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import SearchSummary from '../SearchSummary/SearchSummary';
import SearchResults from '../SearchResults';
import DashboardSkeleton from '../DashboardSkeleton/DashboardSkeleton';
import { SearchDetails as SearchDetailsType } from '../../../services/api/search/types';

interface SearchDetailsProps {
  loading: boolean;
  searchDetails?: SearchDetailsType;
}

const SearchDetails: React.FC<SearchDetailsProps> = ({ loading, searchDetails }) => {
  if (loading) return <DashboardSkeleton />;
  if (!searchDetails) return <Typography>No search details available.</Typography>;

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <SearchSummary searchDetails={searchDetails} />
        </Grid>
        <Grid item xs={12} md={12}>
          {<SearchResults searchDetails={searchDetails} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SearchDetails);
