import axios from 'axios';
import axiosRetry from 'axios-retry';
import apiClient from '../apiClient';
import { BASE_API_URL } from '../config';
import { PaginationParams } from '../types';
import { User, UserUpdateFormFields } from './types';

// Configure axios-retry
axiosRetry(axios, {
  retries: 5, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Use exponential backoff
  // attach callback to each retry to handle logging or tracking
  onRetry: (retryCount, error) => console.log(`Retrying request (${retryCount}): ${error}`),
  // Specify conditions to retry on, this is the default
  // which will retry on network errors or idempotent requests (5xx)
  retryCondition: (error) => axiosRetry.isNetworkOrIdempotentRequestError(error),
});

export const fetchMe = (): Promise<User> => {
  return apiClient.get(`${BASE_API_URL}/me`).then((response) => response.data);
};

export const fetchUsers = (
  payload: PaginationParams,
): Promise<{ data: User[]; totalCount: string }> => {
  return apiClient.get(`${BASE_API_URL}/users`, { params: payload }).then((response) => {
    return { data: response.data, totalCount: response.headers['x-total-count'] };
  });
};

export const updateUser = (userId: string, payload: UserUpdateFormFields) => {
  return apiClient
    .put(`${BASE_API_URL}/users/${userId}`, payload)
    .then((response) => response.data);
};

export const createUser = (email: string): Promise<User> => {
  return apiClient.post(`${BASE_API_URL}/users`, { email }).then((response) => response.data);
};

export const resendUserVerification = (userId: string): Promise<User> => {
  return apiClient.post(`${BASE_API_URL}/users/${userId}/resend`).then((response) => response.data);
};

export const setUserPassword = (
  subId: string,
  password: string,
  tempToken: string,
): Promise<User> => {
  return axios
    .put(
      `${BASE_API_URL}/users-verification/${subId}`,
      { password },
      { headers: { Authorization: tempToken } },
    )
    .then((response) => response.data);
};

export const verifyUser = (subId: string, confirmation_code: string) => {
  return axios
    .post(`${BASE_API_URL}/users-verification/${subId}`, { confirmation_code })
    .then((response) => response);
};

export const generatePassword = () => {
  return axios.get(`${BASE_API_URL}/users-random-password`).then((response) => response.data);
};

export const sendResetPasswordLink = (email: string) => {
  return axios.post(`${BASE_API_URL}/forgot-password`, { email }).then((response) => response.data);
};

export const confirmPasswordReset = (
  subId: string,
  payload: { confirmation_code: string; password: string },
): Promise<User> => {
  return axios
    .post(`${BASE_API_URL}/forgot-password/${subId}`, payload)
    .then((response) => response.data);
};
