import { useQuery } from '@tanstack/react-query';
import { fetchFullSearchById } from '../requests';
import { Status } from '../../../../enums';

const useFetchFullSearch = (searchId: string) => {
  return useQuery({
    queryKey: ['fullSearch', searchId],
    queryFn: () => fetchFullSearchById(searchId),
    enabled: Boolean(searchId),
    refetchInterval: (response) => {
      const data = response.state.data;

      if (data?.grading_status === Status.COMPLETE || response.state.error) {
        return false;
      }

      return 2000;
    },
  });
};

export default useFetchFullSearch;
