enum OnboardingIds {
  NEW_SEARCH_BUTTON = 'new-search-button',
  RECENT_SEARCHES = 'recent-searches',
  SEARCH_TITLE = 'search-title',
  TOPICS_TAB = 'topics-tab',
  TOPIC_ROW = 'topic-row',
  TOPIC_NAME = 'topic-name',
  TOPIC_DESCRIPTION = 'topic-description',
  TOPIC_RELEVANCY = 'topic-relevancy',
  TOPIC_DRILL_DOWN = 'topic-drill-down',
  TOPIC_ADD_TO_WORKSPACE = 'topic-add-to-workspace',
  PEOPLE_TAB = 'people-tab',
  PERSON_ROW = 'person-row',
  PERSON_NAME = 'person-name',
  PERSON_DESCRIPTION = 'person-description',
  PERSON_RELEVANCY = 'person-relevancy',
  PERSON_GOOGLE = 'person-google',
  SPONSORS_TAB = 'sponsors-tab',
  SPONSOR_ROW = 'sponsor-row',
  SPONSOR_DESCRIPTION = 'sponsor-description',
  SPONSOR_RELEVANCY = 'sponsor-relevancy',
}

export default OnboardingIds;
