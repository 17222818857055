import React, { useMemo, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  Button,
  Link,
} from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import styles from './TopicsTable.module.css';
import { TopicResult } from '../../../services/api/search/types';
import { topicsTableConfig, flattenTopicsExtracts, sortCriteria } from './TopicsTable.utils';
import { useTableUtils, useToggle } from '../../../hooks';
import PickFolderModal from '../PickFolderModal/PickFolderModal';
import AddToWorkspaceButton from '../AddToWorkspaceButton';
import TableToolbar from '../../shared/TableToolbar/TableToolbar';
import SortableTableCell from '../../shared/SortableTableCell/SortableTableCell';
import { useRunSearch } from '../../../services/api/search/hooks';
import { useFetchFolders } from '../../../services/api/workspace/folders/hooks';
import { useManageTopicFolders } from '../../../services/api/workspace/topics/hooks';
import { Folder } from '../../../services/api/workspace/types';
import { exportToCsv, exportToExcel } from '../../../utils/export/export';
import { getDomainName, pickMatchScoreLabelColor } from '../../../utils/string/string';
import { OnboardingIds } from '../../../enums';

interface TopicsTableProps {
  data: TopicResult[];
}

const {
  defaultRowsPerPage,
  exportHeaders,
  exportFileName,
  getExportRows,
  filterByKeyword,
  rowsPerPageOptions,
} = topicsTableConfig;

const TopicsTable: React.FC<TopicsTableProps> = ({ data }) => {
  const [pickFolderModalOpen, togglePickFolderModalOpen] = useToggle(false);
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>(null);
  const [initialSelectedFoldersIds, setInitialSelectedFoldersIds] = useState<string[]>([]);

  const { mutate: runSearch } = useRunSearch();
  const { mutate: manageTopicFolders } = useManageTopicFolders();

  const { data: folders, isFetched: foldersFetched } = useFetchFolders({});

  const flattenedTopics = flattenTopicsExtracts(data);
  const {
    data: paginatedTopics,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
    selectedCriterion,
    updateCriterion,
  } = useTableUtils(flattenedTopics, defaultRowsPerPage, filterByKeyword);

  const topicsIdsInFolders = useMemo(
    () =>
      folders.reduce((acc: string[], item: Folder) => {
        return [...acc, ...item.topic_extracts_ids];
      }, []),
    [folders],
  );

  const exportRows = getExportRows(flattenedTopics);

  const handleSave = (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => {
    if (selectedTopicId) {
      manageTopicFolders({ foldersIdsToAdd, foldersIdsToRemove, topicExtractId: selectedTopicId });
    }
  };

  const handleToggleFolderButton = (topicId: string) => {
    const topicsFoldersIds = folders.reduce((acc: string[], item: Folder) => {
      if (item.topic_extracts_ids.includes(topicId)) return [...acc, item.id];
      return acc;
    }, []);

    setSelectedTopicId(topicId);
    setInitialSelectedFoldersIds(topicsFoldersIds);
    togglePickFolderModalOpen();
  };

  const handleExportExcel = () => {
    exportToExcel(exportFileName, exportHeaders, exportRows);
  };

  const handleExportCSV = () => {
    exportToCsv(exportFileName, exportHeaders, exportRows);
  };

  return (
    <Paper className={styles.container}>
      <TableToolbar
        onFilter={onFilter}
        onExportCSV={handleExportCSV}
        onExportExcel={handleExportExcel}
        sortOptions={{
          sortCriteria,
          onCriterionClick: updateCriterion,
          selectedCriterion,
        }}
      />
      {foldersFetched && (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCell
                    columnKey="name"
                    hoveredColumn={hoveredColumn}
                    sortConfig={sortConfig}
                    setHoveredColumn={setHoveredColumn}
                    requestSort={requestSort}
                  >
                    Trending Topic
                  </SortableTableCell>
                  <TableCell>Description</TableCell>
                  <SortableTableCell
                    columnKey="match_score"
                    hoveredColumn={hoveredColumn}
                    sortConfig={sortConfig}
                    setHoveredColumn={setHoveredColumn}
                    requestSort={requestSort}
                    tableCellContentStyle={{ marginLeft: 24 }}
                    align="center"
                  >
                    Relevancy score
                  </SortableTableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTopics.map((topic, index) => {
                  const isFirstElement = index === 0;
                  return (
                    <TableRow
                      id={isFirstElement ? OnboardingIds.TOPIC_ROW : undefined}
                      key={topic.id}
                    >
                      <TableCell>
                        <Typography
                          id={isFirstElement ? OnboardingIds.TOPIC_NAME : undefined}
                          fontWeight={600}
                          variant="body2"
                        >
                          {topic.name}
                        </Typography>
                      </TableCell>
                      <TableCell id={isFirstElement ? OnboardingIds.TOPIC_DESCRIPTION : undefined}>
                        <Typography mb={0.5} variant="body2">
                          {topic.description}
                        </Typography>
                        <Link href={topic.url} target="_blank">
                          {getDomainName(topic.url)}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            <>
                              Relevancy: {topic.relevancy_grade}/4 <br />
                              Specificity: {topic.specificity_grade}/4 <br />
                              Trending: {topic.recency_grade}/4
                            </>
                          }
                          placement="top"
                        >
                          <Typography
                            id={isFirstElement ? OnboardingIds.TOPIC_RELEVANCY : undefined}
                            color={pickMatchScoreLabelColor(topic.match_score)}
                            fontWeight={600}
                            variant="body2"
                            sx={{ cursor: 'pointer' }}
                          >
                            {Math.round(topic.match_score)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Box className={styles.actions}>
                          <AddToWorkspaceButton
                            id={isFirstElement ? OnboardingIds.TOPIC_ADD_TO_WORKSPACE : undefined}
                            onClick={() => handleToggleFolderButton(topic.id)}
                            defaultValue={topicsIdsInFolders.includes(topic.id)}
                            buttonClassName={styles.addToWorkspaceButton}
                          />
                        </Box>
                        <Box className={styles.actions}>
                          <Tooltip placement="top" title="Start a new search based on this topic">
                            <Button
                              size="small"
                              color="info"
                              variant="outlined"
                              onClick={() => runSearch(topic.name)}
                              id={isFirstElement ? OnboardingIds.TOPIC_DRILL_DOWN : undefined}
                            >
                              <TravelExploreIcon />
                            </Button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={flattenedTopics.length}
            rowsPerPage={rowsPerPage}
            page={page}
            defaultValue={defaultRowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {pickFolderModalOpen && (
            <PickFolderModal
              open={pickFolderModalOpen}
              onClose={togglePickFolderModalOpen}
              onSave={handleSave}
              initialSelectedFoldersIds={initialSelectedFoldersIds}
              folders={folders}
            />
          )}
        </Box>
      )}
    </Paper>
  );
};

export default TopicsTable;
