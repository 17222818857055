import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SearchBox.module.css';
import { Autocomplete } from '@mui/material';
import throttle from 'lodash.throttle';
import { fetchSearchAutocompleteResults } from '../../../services/api/search/requests';

interface SearchBoxProps {
  onSearch: (searchTerm: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, disabled = false, autoFocus = false }) => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<string[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const throttledRefetch = useRef(
    throttle((currentQuery: string) => {
      if (currentQuery.length > 1) {
        fetchSearchAutocompleteResults(currentQuery).then((response) => setOptions(response));
      }
    }, 920),
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    throttledRefetch(event.target.value);
  };

  const handleAutocompleteChange = (event: React.SyntheticEvent, value: string | null) => {
    if (value !== null) {
      setQuery(value);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!disabled && query.length) {
      onSearch(query);
    }
  };

  return (
    <Box
      component="form"
      className={styles.searchBox}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <Autocomplete
        freeSolo
        options={options}
        getOptionLabel={(option) => option}
        onChange={handleAutocompleteChange}
        filterOptions={(options) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            className={styles.textField}
            placeholder="e.g. sustainable railways in India, AI startups in 2025"
            variant="outlined"
            value={query}
            inputRef={inputRef}
            onChange={handleChange}
            fullWidth
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: styles.inputRoot,
              },
              endAdornment: (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                  className={styles.searchButton}
                >
                  <SearchIcon sx={{ color: 'white' }} />
                </Button>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBox;
