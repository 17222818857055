import React from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import TopicsTable from './TopicsTable/TopicsTable';
import PeopleTable from './PeopleTable/PeopleTable';
import { SearchDetails } from '../../services/api/search/types';
import SponsorsTable from './SponsorsTable/SponsorsTable';
import { ActiveTabsIndexes } from '../../constants';
import { useUserSettingsStore } from '../../stores';
import { OnboardingIds } from '../../enums';
interface SearchResultsProps {
  searchDetails: SearchDetails;
}

const tabs = [
  { label: 'Topics', id: OnboardingIds.TOPICS_TAB },
  { label: 'People', id: OnboardingIds.PEOPLE_TAB },
  { label: 'Sponsors', id: OnboardingIds.SPONSORS_TAB },
];

const SearchResults: React.FC<SearchResultsProps> = ({ searchDetails }) => {
  const [activeTabIndex, setActiveTabIndex] = useUserSettingsStore((store) => [
    store.activeTabIndex,
    store.setActiveTabIndex,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTabIndex(newTab);
  };

  return (
    <Box sx={{ width: '100%', p: 1, boxSizing: 'border-box' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTabIndex} onChange={handleTabChange} aria-label="search results tabs">
          {tabs.map(({ label, id }) => (
            <Tab id={id} key={label} label={label} />
          ))}
        </Tabs>
      </Box>
      <TabPanel
        activeTab={activeTabIndex}
        index={ActiveTabsIndexes.TOPICS}
        renderContent={() => <TopicsTable data={searchDetails.topic_results} />}
      />
      <TabPanel
        activeTab={activeTabIndex}
        index={ActiveTabsIndexes.PEOPLE}
        renderContent={() => <PeopleTable data={searchDetails.people_results} />}
      />
      <TabPanel
        activeTab={activeTabIndex}
        index={ActiveTabsIndexes.SPONSORS}
        renderContent={() => <SponsorsTable data={searchDetails.sponsors_results} />}
      />
    </Box>
  );
};

interface TabPanelProps {
  activeTab: number;
  index: number;
  renderContent: () => React.ReactNode;
}

const TabPanel = ({ activeTab, index, renderContent }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={activeTab !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {activeTab === index && (
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Box>
    )}
  </Box>
);

export default SearchResults;
