import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SearchPanel.module.css';
import SearchBox from '../SearchBox/SearchBox';

interface SearchPanelProps {
  handleSearch: (searchTerm: string) => void;
}

const SearchPanel: React.FC<SearchPanelProps> = ({ handleSearch }) => (
  <Box className={styles.container}>
    <Box className={styles.content}>
      <SearchIcon className={styles.searchIcon} />
      <Typography fontSize={20} mt={2}>
        Start a new search!
      </Typography>
      <Typography className={styles.description} color="text.secondary">
        Enter an industry or theme you’d like to explore further
      </Typography>
      <SearchBox onSearch={handleSearch} autoFocus />
    </Box>
  </Box>
);

export default SearchPanel;
