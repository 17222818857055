import { Typography } from '@mui/material';
import React from 'react';
import { OnboardingIds } from '../../enums';
import { Step } from 'react-joyride';

export const steps: Step[] = [
  {
    target: 'body',
    content: (
      <Typography variant="body1">
        <strong>Welcome to EventProducer Pro!</strong> You’ve successfully done your first search,
        and we’re already gathering the best topics, speakers, and sponsors from across the web. It
        may take a while, so let’s show you around in the meantime!
      </Typography>
    ),
    placement: 'center',
    disableBeacon: true,
    styles: {
      tooltip: {
        textAlign: 'center',
        maxWidth: '400px',
      },
    },
  },
  {
    target: `#${OnboardingIds.SEARCH_TITLE}`,
    content: (
      <Typography variant="body1">
        <strong>This is your search name! </strong> It’s where all the magic begins. Simply tell us
        an industry or theme of interest and we’ll handle all the hard work. Using advanced AI
        engineering we search the entire web and filter results by a lot of criteria to deliver only
        the most relevant topics, speakers, and sponsors — all in one place. <br />
        <br />
        Let’s dive into your first Topics result!
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.TOPIC_ROW}`,
    content: (
      <Typography variant="body1">
        <strong>Here’s your first potential topic! </strong> We’ve analyzed tons of data to bring
        you the topics that will resonate with the audience the most. This one has been carefully
        selected for its high relevance to your search. <br />
        <br />
        Let’s see what it has in store!
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.TOPIC_NAME}`,
    content: (
      <Typography variant="body1">
        <strong>This is the topic name!</strong> Every topic we suggest is unique since we use the
        power of AI to remove any duplicates or similar results.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.TOPIC_DESCRIPTION}`,
    content: (
      <Typography variant="body1">
        <strong>This is the brief description of the topic!</strong> We’ve summarized the key points
        and highlighted the reasons why the topic can attract more attendees. Want to explore it
        more? Follow the link to the topic source below.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.TOPIC_RELEVANCY}`,
    content: (
      <Typography variant="body1">
        <strong>This is the topic relevancy score!</strong> It shows just how attractive the topic
        is to your potential audience. No more guesswork – we’ve already graded all the results
        using advanced AI engineering and chosen only the most relevant for you.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.TOPIC_ADD_TO_WORKSPACE}`,
    content: (
      <Typography variant="body1">
        Found a topic you love? <strong>Add it to the Workspace</strong> to keep everything
        organized in one place. No more juggling tabs or scattered notes — everything you choose
        stays safe and ready for building your agenda.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.TOPIC_DRILL_DOWN}`,
    content: (
      <Typography variant="body1">
        Want to dive deeper into this topic and find more results around it?{' '}
        <strong>Hit the Drilldown button</strong>, and we’ll instantly run a new search using the
        topic name.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.PEOPLE_TAB}`,
    content: (
      <Typography variant="body1">
        Your first <strong>People results are in!</strong> Check them here and discover key experts,
        speakers, and influencers related to the industry or theme of interest.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.PERSON_ROW}`,
    content: (
      <Typography variant="body1">
        <strong>Here’s your first potential speaker!</strong> Based on your search, we’ve found
        experts across the whole web who stand out within the industry. This person could be a great
        addition to your event.
        <br />
        <br /> Let’s learn more about them!
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.PERSON_NAME}`,
    content: (
      <Typography variant="body1">
        <strong>This is the person’s name and position!</strong> We show the most up-to-date
        position available online which reflects either their current or the most recognized role.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.PERSON_DESCRIPTION}`,
    content: (
      <Typography variant="body1">
        <strong>This is the summary of the person’s activities!</strong> We’ve highlighted their key
        career milestones and why they could capture your audience’s attention.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.PERSON_RELEVANCY}`,
    content: (
      <Typography variant="body1">
        <strong>This is the person’s relevancy score!</strong> It shows how well this speaker could
        grab your audience’s attention. No more guessing – we’ve graded all the results and ensured
        they are a great fit for the event with the power of AI.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.PERSON_GOOGLE}`,
    content: (
      <Typography variant="body1">
        Want to learn more about this person? <strong>Hit the Google button</strong> to instantly
        search for more information, including their background and contact details to secure them
        for your event.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.SPONSORS_TAB}`,
    content: (
      <Typography variant="body1">
        Your first <strong>Sponsor results are in!</strong> Check them here and discover companies
        and brands who could become valuable partners for your event or provide sponsorship
        opportunities.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.SPONSOR_ROW}`,
    content: (
      <Typography variant="body1">
        <strong>Here’s your first potential sponsor!</strong> We’ve found companies and brands like
        this one that could be interested in sponsoring events in your industry, giving you a head
        start in securing valuable partners.
        <br />
        <br />
        Let’s find out what it has to offer!
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.SPONSOR_DESCRIPTION}`,
    content: (
      <Typography variant="body1">
        <strong>Here’s the company’s brief description!</strong> We include information about the
        company’s targeted audience to help you determine if it matches your attendees. You can also
        find out any past events the company has sponsored if those are available online.
        <br />
        <br /> Interested in a potential partnership? Follow the link to the company’s website
        below.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.SPONSOR_RELEVANCY}`,
    content: (
      <Typography variant="body1">
        <strong>This is the sponsor’s relevancy score!</strong> It shows how interested the company
        might be to reach out to the audience at your event and provide sponsorship opportunities.
        We’ve selected the best leads based on their previous activity and interests to help you
        secure crucial partnerships early.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.NEW_SEARCH_BUTTON}`,
    content: (
      <Typography variant="body1">
        Ready to explore something new? <strong>Hit the New Search button</strong> to start fresh
        and dive into another industry or theme of interest at any time. Keep your searches focused
        — one topic at a time — to get the most precise results tailored to your needs.
      </Typography>
    ),
    disableBeacon: true,
  },
  {
    target: `#${OnboardingIds.RECENT_SEARCHES}`,
    placement: 'right',
    content: (
      <Typography variant="body1">
        <strong>Here’s the Recent Searches section!</strong> All your past searches within the event
        are saved here. Once the results are downloaded, they’ll stay accessible allowing you to
        revisit them anytime. Thanks for taking the time to explore! We can’t wait to see the
        amazing work you’ll create!
      </Typography>
    ),
    disableBeacon: true,
  },
];

export const stepIndexById: Record<string, number> = steps.reduce(
  (acc, step, index) => {
    const target = step.target as string;
    const targetId = target.replace('#', '');
    acc[targetId] = index;
    return acc;
  },
  {} as Record<string, number>,
);
